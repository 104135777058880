import React, { useState, useEffect } from "react";
import "./header.scss";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import Logo2 from "../../assets/logo2.png";
import { navLinks } from "../../data/data";
import { HiOutlineMenu } from "react-icons/hi";
import { MdClose } from "react-icons/md";

const Nav = () => {
  const [isNavLinksShowing, setIsNavLinkShowing] = useState(false);
  const [changeLogo, setChangeLogo] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  useEffect(() => {
    const handleScroll = () => {
      const nav = document.querySelector("nav");
      if (nav) {
        const isScrolled =
          window.scrollY > 40 ||
          document.body.scrollTop > 40 ||
          document.documentElement.scrollTop > 40;
        nav.classList.toggle("navShadow", isScrolled);
        setChangeLogo(isScrolled);
      }
      setIsNavLinkShowing(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let droupdowndata = [
    "Manufacture",
    "Sourcing",
    "Spinning",
    "Tailoring",
    "Buying",
    "Logistics",
    "Distribution",
    "Design",
    "Image",
    "Consulting",
  ];

  return (
    <nav>
      <div className="container nav-container">
        <Link to="/" className="logo">
          <img
            src={changeLogo ? Logo2 : Logo}
            alt="Logo"
            className={changeLogo ? "logo2 " : ""}
          />
        </Link>

        <ul
          className={`nav-links ${
            isNavLinksShowing ? "navlinksShow" : "navlinksHide"
          }`}>
          {navLinks.map(({ name, path }, index) => {
            return (
              <li key={index}>
                <a
                  href={path}
                  activeClassName="active" // Added activeClassName here as well
                  className={` ${name === "Place Order" ? "active2" : null}`}>
                  {name}
                </a>
              </li>
            );
          })}
        </ul>

        <div className={`nav-right ${isNavLinksShowing ? "show" : ""}`}>
          <button
            className="menu-button"
            onClick={() => setIsNavLinkShowing(!isNavLinksShowing)}>
            {!isNavLinksShowing ? (
              <HiOutlineMenu />
            ) : (
              <MdClose style={{ color: "black" }} />
            )}
          </button>
          {navLinks.map((item, index) => {
            return (
              <a
                key={index}
                href={item.path}
                onClick={() => setIsNavLinkShowing(false)}
                activeClassName={`active ${
                  item.name === "Place Order" ? "active2" : "aa"
                }`} // Added activeClassName here as well
              >
                {item.name}
              </a>
            );
          })}
        </div>

        <div>
          <button
            className="menu-button"
            onClick={() => setIsNavLinkShowing(!isNavLinksShowing)}>
            {!isNavLinksShowing ? <HiOutlineMenu /> : <MdClose />}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Nav;

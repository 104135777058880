import React from "react";
import "./footer.scss";
import Logo from "../../assets/logo.png";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section about">
          <div className="sub-title">
            <img src={Logo} alt="" className="logo-2" />
          </div>
        </div>

        <div className="footer-section contact">
          <div className="heading">CONTACT US </div>
          <p>Email: support@hashstitch.com</p>
          <p>Phone/whatsapp: +91 9148455591</p>
          <p>
            Address: F4, Sri Rama Complex, Indiranagar, Bengaluru, INDIA -
            560075{" "}
          </p>
        </div>
        <div className="footer-section links">
          <div className="heading">Useful Links</div>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/services">Services</a>
            </li>
            <li>
              <a href="/gallery">Factories & Collections</a>
            </li>
            <li>
              <a href="/sustanability">Sustanability</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; 2024 Hashstitch. All rights reserved.
        <div className="socials">
          <a href="/home" className="social-link">
            <FaFacebook />
          </a>
          <a href="/home" className="social-link">
            <FaTwitter />
          </a>
          <a href="/home" className="social-link">
            <FaInstagram />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import PageHeader from "../../components/pageheader";
import ContactUs from "../../components/contact-us/contactus";
import Footer from "../../components/footer/footer";
import OurService2 from "../../components/our-services2/ourservice2";
import OurService3 from "../../components/our-services3/ourservice3";

const Services = () => {
  return (
    <div>
      <PageHeader currentPage={"Services"} banner={"banner-1 banner-2"} />
      {/* <OurService2 /> */}
      <OurService3 />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Services;

import React from 'react'
import './roundtext.scss'

const RoundText = ({ style = {}, text }) => {
  const characters = text.split('')

  return (
    <a href="#contact-us" className="circle-wrapper">
      <div className="circle-bg"></div>
      <div className="rotate-text text-uppercase">
        <p>
          {characters.map((item, index) => (
            <span
              key={index}
              style={{ transform: `rotate(${index * 9.6}deg)` }}
            >
              {item}
            </span>
          ))}
        </p>
      </div>
    </a>
  )
}

export default RoundText

import React, { useState } from 'react'
import './faq.scss'
import faq from '../../assets/faq.png'

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null)

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  const faqData = [
    {
      question: 'Can Hashstitch provide custom designs and branding?',
      answer:
        'Yes, Hastitch offers custom design and branding services to help fashion businesses create a unique and memorable brand identity. Our team of professionals in the industry can work with you to develop a custom design that aligns with your brand’s aesthetic and reflects your unique vision.',
    },
    {
      question: 'What types of products does Hashtich source?',
      answer:
        'We source a wide range of fashion products, including clothing, accessories, jewelry, footwear, and homeware, thanks to our large network of manufacturers and suppliers around the world. Our team is also well-versed in sourcing high-quality products with the correct price and conditions.',
    },
    {
      question: 'How does Hashtitch simplify the buying process for clients?',
      answer:
        'We simplify the buying process for clients by providing them with a pre-existing network of collections from various categories. This allows clients to easily browse and select from a wide range of options, without the need to go through the time-consuming and potentially confusing process of searching for products from multiple sources.',
    },
    {
      question: 'Does Hashtich provide packaging services for garments?',
      answer:
        'Yes, we do. We take a customized approach to packaging, ensuring that the packaging is not only functional but also reflects the brand’s image and identity. We work closely with our clients to understand their preferences and specifications, and we use this information to create a packaging solution that is both functional and visually appealing.',
    },
  ]

  return (
    <div className="faq-container">
      <h1>FAQ</h1>
      <div className="faq-content">
        <div className="faq-image">
          <img src={faq} alt="FAQ" className="cloth-img" />
        </div>
        <div className="faq-list">
          {faqData.map((item, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? 'active' : ''}`}
              onClick={() => toggleFAQ(index)}
            >
              <div className="faq-question">{item.question}</div>
              <div className="faq-answer">{item.answer}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FAQ

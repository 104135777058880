import React from "react";
import "./whatwedo.scss";
import Logo from "../../assets/logo.png";
import garment from "../../assets/pexels-garment.jpg";
import fabric from "../../assets/pexels-woven-swetter.jpg";

const whatwedo = () => {
  return (
    <div>
      <div className="wedo-container">
        <div className="text-section">
          <div className="title">
            <img src={Logo} alt="Logo" />
            See What's New
          </div>
          <div className="sub-text-cont">
            <span className="sub-text">
              BEAUTIFUL & TOUGH. THAT'S LIVABLE LUXURY.
            </span>
          </div>
          <div className="service-item">
            <div className="img-container">
              <a href="gallery">
                <img src={garment} alt="Manufacture" />
                <div className="img-text-1">Fabrics</div>
                <div className="overlay">
                  <div className="img-text">
                    Fabrics
                    <div className="img-subtitle">
                      We make sure that top notch fabric is used for the
                      ultimate user experience. We intend no compromise with the
                      quality of fabric used.
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="img-container">
              <a href="gallery">
                <img src={fabric} alt="Distribution" />
                <div className="img-text-1">Garments</div>
                <div className="overlay">
                  <div className="img-text">
                    Garments
                    <div className="img-subtitle">
                      We at hashstitch are obliged to serve variety with the
                      quality. Hashstitch offers you wide range of products with
                      unique designs and amicable output that is way above
                      satisfaction.
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default whatwedo;

import React from 'react'
import tshirt from '../../assets/tshirt.png'
import jeans from '../../assets/jeans.png'
import jacket from '../../assets/jacket.png'
import dress from '../../assets/dress.png'
import shirt from '../../assets/shirt.png'
import './gallery.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

const Gallery = () => {
  return (
    <div className="gallery-container">
      <h2 className="heading">Collections</h2>
      <Swiper
        spaceBetween={50}
        slidesPerView={3.5}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <div className="clothing-item">
            <img src={tshirt} alt="T-shirt" className="clothing-image" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="clothing-item">
            <img src={jeans} alt="T-shirt" className="clothing-image" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="clothing-item">
            <img src={jacket} alt="T-shirt" className="clothing-image" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="clothing-item">
            <img src={dress} alt="T-shirt" className="clothing-image" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="clothing-item">
            <img src={shirt} alt="T-shirt" className="clothing-image" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="clothing-item">
            <img src={tshirt} alt="T-shirt" className="clothing-image" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Gallery

import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const ServiceList = ({ data }) => {
  return (
    <>
      <NavLink to={data.path} activeClassName="active">
        <div className="wrapper" id={`step-${data.id}`}>
          <div className="list-no">
            <span>No. {data.id}</span>
          </div>
          <div className="list-title">
            <h3>{data.title}</h3>
          </div>
          <div className="list-content">
            <p>{data.description}</p>
          </div>
          <div className="list-icon">
            <FaAngleRight />
          </div>
        </div>
      </NavLink>
    </>
  );
};

export default ServiceList;

import React from "react";
import PageHeader from "../../components/pageheader";
import ContactUs from "../../components/contact-us/contactus";
import Footer from "../../components/footer/footer";
import Faq from "../../components/faq/faq";

const Contact = () => {
  return (
    <div>
      <PageHeader currentPage={"Contact Us"} banner={"banner-1 banner-2"} />
      <ContactUs />
      <Faq />
      <Footer />
    </div>
  );
};

export default Contact;

import React from "react";
import "./banner.scss";
import RoundText from "../roundText";
import logo from "../../assets/logo2.png";

const Banner = () => {
  return (
    <div className="banner-cont">
      <div className="overlay" />
      <video autoPlay muted loop className="videooverlay">
        <source
          src="https://deepwear.info/wp-content/uploads/video.mp4"
          type="video/mp4"
        />
        <source src="alternative-video.webm" type="video/webm" />
        Your browser does not support the video tag.
      </video>
      <div className="banner-content">
        <img src={logo} alt="logo" className="banner-logo" />
        <div className="sub-content">
          Helping you create with people and planet in mind.
        </div>

        <RoundText text={"Request A Meeting Request A Meeting"} />
      </div>
    </div>
  );
};

export default Banner;

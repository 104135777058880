import React from "react";
import PageHeader from "../../components/pageheader";
import ServiceExtended from "../../components/service-extended";
import ContactUs from "../../components/contact-us/contactus";
import Manufacturing1 from "../../components/service-extended/manufacturing1";

const ServiceDetails = () => {
  return (
    <div>
      <PageHeader
        currentPage={"Service Details"}
        banner={"banner-1 banner-2"}
      />
      <Manufacturing1 />
      {/* <ServiceExtended /> */}
      <ContactUs />
    </div>
  );
};

export default ServiceDetails;

import React from 'react'
import './manufacturing.scss'
import sustainability_three from '../../assets/pexels-anete-lusina.jpg'

const Manufacturing = () => {
  return (
    <div className="manufacturing-container">
      <img src={sustainability_three} alt="" className="manufacturing-image" />

      <div className="manufacturing-title ">
        USING OUR MANUFACTURING <br /> KNOW-HOW TO HELP BRANDS <br /> SCALE,
        SUSTAINABLY
        <div className="manufacturing-text">
          We’re on a mission to transform the way the world’s products are made.
          Supply chains have been global for years, but finding the right
          manufacturer—and bringing a physical product to life in a sustainable
          way—is incredibly difficult, even for the world’s biggest brands.
          <br /> <br /> Our focus is on climate action, responsible innovation
          and circular strategies, and we are passionate about creating change
          towards a circular system. We work with designers, brands and
          retailers to integrate new systems and programs to address social and
          environmental sustainability that are relevant and impactful. <br />{' '}
          <br /> By choosing Deepwear, you are making an active choice to
          respect people and our planet. <br /> <br />
          Let’s work together
        </div>
      </div>
    </div>
  )
}

export default Manufacturing

import React from 'react'
import Banner from '../components/banner'
import Aboutus from '../components/about-us/aboutus'
import Whatwedo from '../components/what-we-do/whatwedo'
import Product from '../components/product-type/product'
import Sustainabality from '../components/sustainabality/sustainabality'
import Contactus from '../components/contact-us/contactus'
import Footer from '../components/footer/footer'
import Aboutus3 from '../components/about-us/aboutus3'
import Cloths from '../components/gallery/gallery'

const Home = () => {
  return (
    <>
      <Banner />
      <Aboutus3 />
      <Aboutus />
      <Whatwedo />
      <Sustainabality />
      <Product />
      <Cloths />
      <Contactus />
      <Footer />
    </>
  )
}

export default Home

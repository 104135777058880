import React from "react";
import PageHeader from "../../components/pageheader";
import ContactUs from "../../components/contact-us/contactus";
import Footer from "../../components/footer/footer";
import Sustainablesource from "../../components/sustainable-sourcing/sustainablesource";
import Sustainable2 from "../../components/sustainable-sourcing/sustainable2";
import Manufacturing from "../../components/manufacturing/manufacturing";

const Sustanability = () => {
  return (
    <div>
      <PageHeader currentPage={"Sustanability"} banner={"banner-1 banner-2"} />
      <Sustainablesource />
      <Sustainable2 />
      <Manufacturing />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Sustanability;

import React from "react";
import "./aboutus.scss";
import aboutUs from "../../assets/About_Us.png";
import Logo from "../../assets/logo.png";

const AboutUs = () => {
  return (
    <div className="about-us-container" style={{ background: "white" }}>
      <div className="image-cont">
        <img src={aboutUs} alt="" className="img-2" />
      </div>
      <div className="text-section">
        <h1 className="title">
          <img src={Logo} alt="Logo" className="small-logo" />
          Stitch
        </h1>
        <h1 className="title">From India to Anywhere</h1>
        <div className="text-sub-section">
          <div className="text">
            India’s textile garments are renowned for their quality and
            craftsmanship, making them highly sought after globally. Our
            export-grade packaging ensures that every garment reaches its
            destination in perfect condition. We use advanced packaging
            materials that protect against moisture, dust, and handling damage.
            Coupled with a robust logistics network, we guarantee timely and
            efficient delivery to any part of the world. Our logistics partners
            are experienced in handling international shipments, ensuring smooth
            customs clearance and delivery. From India to anywhere, we ensure
            that our garments not only meet but exceed global standards,
            reaching you in pristine condition.
          </div>
        </div>
        <div>
          <button className="cta-button">Subscribe Here</button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

import React from "react";

const Step = ({ number, text, isLeft }) => {
  return (
    <div className={`step ${isLeft ? "left" : "right"}`} id={`step-${number}`}>
      <div className="step-content">
        <div className="number">{number}</div>
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

export default Step;

import React from 'react'
import './sustainablesource.scss'
import sustainability_one from '../../assets/pexels-sustaiability_1.jpg'
import sustainability_two from '../../assets/pexels-sustainability.jpg'

const sustainablesource = () => {
  return (
    <div className="sustainablesource-container">
      <div className="title-1">
        SUSTAINABLE SOURCING IS THE WAY <br /> <br /> FORWARD
      </div>
      <div className="text-sub-section-1">
        <div className="sub-title">
          Manage your social and environmental compliance with Us
        </div>
        <div className="sub-para">
          <div className="text">
            In the wake of changing consumption patterns, the fashion industry
            is getting a revamp. Conventional supply chains and manufacturing
            approaches are not the best approach to the renewable, sustainable
            goods favoured by consumers, employees, investors, and regulators.
            Deepwear has a comprehensive framework to improve sustainability in
            fashion
          </div>
          <div className="text">
            We aim to enable our clients to source more sustainably by
            integrating a wide portfolio of industry-relevant certificates into
            our supplier / factory audits. It is now very important that brands
            look at all angles of their business—especially the way they
            function and the core values—to make sure sustainability is
            practiced, deal with climate change, and promote ethical business
            practices.
          </div>
        </div>
        <div className="img-container">
          <img src={sustainability_one} alt="" className="cloth-img" />
          <img src={sustainability_two} alt="" className="cloth-img" />
        </div>
      </div>
    </div>
  )
}

export default sustainablesource

import React from "react";
import { NavLink } from "react-router-dom";
import "./mainpage.scss";

const PaginationPart = ({ filteredProducts }) => {
  return (
    <>
      <div className="coll-dis-gallery">
        {filteredProducts.map((product) => (
          <NavLink
            to={`/gallery/${product.name}`}
            state={product}
            activeClassName="active"
            key={product.id}>
            <div key={product.id} className="disp-product-card">
              <img
                src={`https://hash-stitch.onrender.com${product.first_image}`}
                alt={product.name}
                className="coll-img"
              />
              <div className="disp-product-card-text">
                <h6>{product.name}</h6>
                <p>{product.description}</p>
              </div>
            </div>
          </NavLink>
        ))}
      </div>
    </>
  );
};

export default PaginationPart;

import React, { useEffect } from "react";
import "./ourservice3.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import ServiceList from "./servicelist";

gsap.registerPlugin(ScrollTrigger);

const OurService3 = () => {
  const services = [
    {
      path: "/services/manufacture",
      id: 1,
      title: "Manufacture",
      description:
        "We provide high-quality manufacturing services to meet your needs. Our state-of-the-art facilities ensure that we produce the best products for our clients.",
    },
    {
      path: "/services/sourcing",
      id: 2,
      title: "Sourcing",
      description:
        "Our reliable sourcing services ensure you get the best materials available. We work with trusted suppliers to provide high-quality resources.",
    },
    {
      path: "/services/spinning",
      id: 3,
      title: "Spinning",
      description:
        "Our expert spinning services guarantee the production of superior yarn. We use the latest technology to ensure the best results.",
    },
    {
      path: "/services/tailoring",
      id: 4,
      title: "Tailoring",
      description:
        "We offer custom tailoring services to fit your exact specifications. Our experienced tailors ensure precision and quality in every garment.",
    },
    {
      path: "/services/buying",
      id: 5,
      title: "Buying",
      description:
        "We currently represent over 100 collections that can be purchased as they are, or re-labelled & amended to suit your target market and branding requirements.",
    },
    {
      path: "/services/logistics",
      id: 6,
      title: "Logistics",
      description:
        "Hashstitch provides full logistical support, including label stock holding, packing, transportation and warehousing. We can offer our clients consolidated shipping solutions.",
    },
    {
      path: "/services/distribution",
      id: 7,
      title: "Distribution",
      description:
        "Hashstitch is an independent agency offering integrated solutions from production to distribution.",
    },
    {
      path: "/services/design",
      id: 8,
      title: "Design",
      description:
        "Hashstitch is an independent agency offering integrated solutions from production to distribution.",
    },
    {
      path: "/services/image",
      id: 9,
      title: "Image",
      description:
        "We do it all! You just need to let us know your needs and we will take care of everything for you.",
    },
    {
      path: "/services/consulting",
      id: 10,
      title: "Consulting",
      description:
        "Over the last 14 years, our consultancy has grown into a highly personalized experience, with which Hashstitch have.",
    },
  ];

  useEffect(() => {
    // Register GSAP animations for each service item
    services.forEach((step) => {
      gsap.fromTo(
        `#step-${step.id}`,
        { opacity: 0, y: 80 }, // Start off-screen to the right
        {
          opacity: 1,
          y: 0, // End at the original position
          duration: 2,
          scrollTrigger: {
            trigger: `#step-${step.id}`,
            start: "top 80%",
            end: "top 60%",
            scrub: 1,
          },
        }
      );
    });

    // Cleanup function to kill all scroll triggers on component unmount
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []); // Add `services` to dependencies to watch for changes

  return (
    <main className="service">
      <div className="service-container" id="service-section">
        <div className="service-disciption">
          <aside>
            <h2>OUR SERVICES</h2>
            <p>
              Deepwear provides end-to-end solutions for all your needs in
              setting up a new brand or developing your current one. We are one
              of the leading fashion consulting firms in the world with
              experience in catapulting fashion brands to soar to new heights of
              success.
            </p>
          </aside>
        </div>
        <div className="service-list">
          <div className="service-block">
            {services.map((data) => (
              <ServiceList key={data.id} id={`step-${data.id}`} data={data} />
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default OurService3;

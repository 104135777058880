import React, { useEffect, useState, useCallback } from "react";
import "./fabric.scss";
import PaginationPart from "./pagination";
import ReactPaginate from "react-paginate";
import axiosInstance from "../helper";
import { TailSpin } from "react-loader-spinner";

const ITEMS_PER_PAGE = 15;

const FabricCollections = () => {
  const [isLoding, setIsLoding] = useState(true);

  const [products, setProducts] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);

  const filteredProducts = products;
  const currentItems = filteredProducts.slice(
    itemOffset,
    itemOffset + ITEMS_PER_PAGE
  );
  const pageCount = Math.ceil(filteredProducts.length / ITEMS_PER_PAGE);
  console.log({ pageCount });

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * ITEMS_PER_PAGE) % filteredProducts.length;
    console.log({ event, newOffset });

    setItemOffset(newOffset);
  };

  const fetchData = useCallback(async () => {
    console.log("fabric fetch");
    try {
      let fabric_data = await axiosInstance.get("product/fabric-type/");

      setProducts(fabric_data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoding(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="ecommerce-container">
      <aside className="coll-dis-gallery-container">
        {isLoding ? (
          <div className="is_loading">
            <TailSpin type="ThreeDots" color="Black" height={80} width={80} />
            <h4> Loading . . .</h4>
          </div>
        ) : currentItems.length === 0 ? (
          <h1>Products Not Found</h1>
        ) : (
          <PaginationPart filteredProducts={currentItems} />
        )}
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          className="paginate-cont"
        />
      </aside>
    </div>
  );
};

export default FabricCollections;

import React from 'react'
import './sustainable2.scss'
import cloth3 from '../../assets/cloth3.png'

const sustainable2 = () => {
  return (
    <div className="sustainable2-cont">
      <div className="text">
        As sustainability and transparency have become key drivers for the
        apparel industry’s way forward, these partnerships offer completely new
        methods in which brands and buyers can discover certified factories and
        ensure they are safely and efficiently compliant. <br /> <br />{' '}
        Companies need to be more aware of the social, environmental and
        economical impact they have and should be more aware of their Ecological
        footprint. We’re on a mission to transform the way the world’s products
        are made. Supply chains have been global for years, but finding the
        right manufacturer—and bringing a physical product to life in a
        sustainable way—is incredibly difficult, even for the world’s biggest
        brands. <br /> <br /> We work together with clients to set goals. We
        then define an operating model that will help them meet those goals.
      </div>
      <img src={cloth3} alt="" className="cloth-img  cloth3" />
    </div>
  )
}

export default sustainable2

import React, { useState, useRef, useEffect } from "react";
import "./product.scss"; // Ensure this file is imported to apply the custom styles

const CustomMultiSelect = ({
  options,
  selectedOptions,
  setSelectedOptions,
  placeholder,
  isOpen,
  setIsOpen,
  noOptionsMessage, // Add this prop
}) => {
  const dropdownRef = useRef(null);

  const handleSelect = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  return (
    <div className="custom-multiselect" ref={dropdownRef}>
      <span className="dropdown-toggle" onClick={() => setIsOpen(!isOpen)}>
        {selectedOptions.length > 0 ? (
          selectedOptions.join(", ")
        ) : (
          <span className="text-gray-400">{placeholder}</span>
        )}
        <svg
          className={`dropdown-icon ${isOpen ? "open" : ""}`}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
        >
          <path
            d="M6 9L12 15L18 9"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
      {isOpen && (
        <div className="dropdown-menu">
          {options.length > 0 ? (
            options.map((option) => (
              <span
                key={option}
                className="dropdown-item"
                onClick={() => handleSelect(option)}
              >
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleSelect(option)}
                  className="checkbox"
                />
                <span>{option}</span>
              </span>
            ))
          ) : (
            <span className="dropdown-item">{noOptionsMessage}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;

import React from "react";
import "./shipping-information.scss";
import { MdClear } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ShippingInformation = ({
  show,
  onClose,
  shippingInfo,
  setShippingInfo,
  onSubmitShipping,
}) => {
  if (!show) {
    return null;
  }

  const inputdata = [
    {
      label: "Full Name",
      input_type: "text",
      name: "full_name", // Fixed name attribute for shippingInfo mapping
      placeholder: "user name",
    },
    {
      label: "Email",
      input_type: "email",
      name: "email", // Fixed name attribute for shippingInfo mapping
      placeholder: "email",
    },
    {
      label: "Contact Number",
      input_type: "number",
      name: "contact_number", // Fixed name attribute for shippingInfo mapping
      placeholder: "number",
    },
    {
      label: "Company Name",
      input_type: "text",
      name: "company_name", // Fixed name attribute for shippingInfo mapping
      placeholder: "company name",
    },
  ];

  const handleChange = (e) => {
    setShippingInfo({ ...shippingInfo, [e.target.name]: e.target.value });
  };

  return (
    <div className="modal-overlay-si" onClick={onClose}>
      <div className="modal-content-si" onClick={(e) => e.stopPropagation()}>
        <div className="modal-body-si">
          <div className="modal-body-head-si">
            <div>
              <h1>Shipping Information</h1>
              <p>We ship within 5-7 working days</p>
            </div>
            <button className="modal-close" onClick={onClose}>
              <MdClear />
            </button>
          </div>

          <aside className="modal-body-form">
            <form
              className="contact-form-si"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitShipping();
              }}>
              {inputdata.map((input) => (
                <div className="form-group" key={input.name}>
                  <label>{input.label}</label>
                  {input.input_type == "number" ? (
                    <PhoneInput
                      country={"in"}
                      value={shippingInfo["contact_number"]}
                      onChange={(phone) =>
                        handleChange({
                          target: { name: "contact_number", value: phone },
                        })
                      }
                      placeholder="contact-number"
                      inputStyle={{
                        position: "relative",
                        left: "40px",
                        width: window.innerWidth <= 768 ? "88%" : "92%",
                        color: "black",
                        border: "1px solid #ccc",
                      }}
                      buttonStyle={{ padding: "0px 2px" }}
                    />
                  ) : (
                    <input
                      type={input.input_type}
                      name={input.name}
                      placeholder={input.placeholder}
                      value={shippingInfo[input.name] || ""}
                      onChange={handleChange}
                      required
                    />
                  )}
                </div>
              ))}
              <div className="form-group">
                <label htmlFor="dn">Delivery Note</label>
                <textarea
                  name="delivery_note"
                  id="dn"
                  placeholder="Add any specific delivery instructions"
                  value={shippingInfo.delivery_note || ""}
                  onChange={handleChange}
                />
              </div>
              <button type="submit" className="sub-button">
                Submit Shipping Information
              </button>
            </form>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default ShippingInformation;

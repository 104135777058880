import React from "react";
import "./aboutus.scss";
import ourTeam from "../../assets/Our_team.png";
import Logo from "../../assets/logo.png";

const AboutUs3 = () => {
  return (
    <div className="about-us-container">
      <div className="text-section">
        <h1 className="title">
          <img src={Logo} alt="Logo" className="small-logo" />
          Stitch
        </h1>

        <h1 className="title">Luxury Fabrics X Craftmanship</h1>

        <div className="text-sub-section">
          <div className="text">
            In the world of textile garments manufacturing, the fusion of luxury
            fabric with expert craftsmanship creates unparalleled fashion. At
            our company, we source the finest fabrics globally, ensuring each
            piece exudes elegance and quality. Our skilled artisans meticulously
            craft each garment, paying attention to every detail, from precise
            stitching to flawless finishing. This dedication to excellence
            ensures that every piece not only looks exquisite but also stands
            the test of time. By combining luxurious materials with superior
            craftsmanship, we bring to life garments that epitomize
            sophistication and style, making a statement of quality and
            distinction in every thread.
          </div>
        </div>
        <div>
          <button className="cta-button">Subscribe Here</button>
        </div>
      </div>
      <div className="image-cont">
        <img src={ourTeam} alt="Craftsmanship Collage" className="img-1" />
      </div>
    </div>
  );
};

export default AboutUs3;

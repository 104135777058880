import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "../components/header";
import Services from "../pages/services";
import Gallery from "../pages/gallery";
import Sustanability from "../pages/sustanability";
import Contact from "../pages/contact";
import Home from "../pages/home";
import Notfound from "../pages/NotFound/notFound";
import AboutUs from "../pages/aboutus";
import ScrollToTop from "../scroll/scrolltotop";
import ServiceDetails from "../pages/serviceDetails";
import ProductDetails from "../pages/product-deatail";

export default function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/sustanability" element={<Sustanability />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services/:type" element={<ServiceDetails />} />
        <Route path="/gallery/:type" element={<ProductDetails />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
}

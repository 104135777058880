import PageHeader from "../../components/pageheader";
import ContactUs from "../../components/contact-us/contactus";
import Footer from "../../components/footer/footer";
import Main from "../../components/factories/main";
import "./factories.scss";
import FactCollections from "../../components/factories-collections/mainpage";

const Gallery = () => {
  return (
    <div>
      <PageHeader
        currentPage={"FACTORIES & COLLECTIONS"}
        banner={"banner-1 banner-2"}
      />
      <div className="filter-screen ">
        {/* <Main /> */}
        <FactCollections/>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Gallery;

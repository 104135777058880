import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import Step from "./step.js";
import "./product.scss";

gsap.registerPlugin(ScrollTrigger);

const steps = [
  { number: 1, text: "Fabric Sourcing" },
  { number: 2, text: "Dye" },
  { number: 3, text: "Stitching" },
  { number: 4, text: "Mass Produce" },
  { number: 5, text: "Packaging & Logistics" },
  { number: 6, text: "To Customer" },
];

const Product = () => {
  useEffect(() => {
    steps.forEach((step, index) => {
      gsap.fromTo(
        `#step-${step.number}`,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: `#step-${step.number}`,
            start: "top 80%",
            end: "top 60%",
            scrub: 1,
          },
        }
      );
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div className="product-wrapper">
      <div className="steps-container">
        <h2>How It's Done</h2>
        {steps.map((step, index) => (
          <Step
            key={step.number}
            id={`step-${step.number}`}
            number={step.number}
            text={step.text}
            isLeft={index % 2 === 0}
          />
        ))}
      </div>
    </div>
  );
};

export default Product;

import React from "react";
import "./fabric.scss";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper/modules";
const GallerySwiper = ({ images }) => {
  return (
    <div className="product-gallery-container">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={10}
        slidesPerView={1} // Show one image per slide
        navigation // Enable arrows
        pagination={{ clickable: true }} // Enable pagination
        onSlideChange={() => console.log("Slide changed")}
        onSwiper={(swiper) => console.log("Swiper initialized", swiper)}>
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="clothing-item">
              <img src={image.image} alt="product" className="clothing-image" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default GallerySwiper;

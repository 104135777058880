import React from 'react'
import './pageheader.scss' // Import your SCSS file for styling
import RoundText from '../roundText'
// import logo from "../../assets/logo2.png";

const PageHeader = ({ currentPage, banner }) => {
  return (
    <section className={`banner-section ${banner}`}>
      <div className="container">
        <div className="banner-wrapper">
          <div className="banner-heading">{currentPage}</div>
          {/* <img src={logo} alt="logo" className="page-banner-logo" /> */}

          <RoundText text={'Request A Meeting Request A Meeting'} />
        </div>
      </div>
    </section>
  )
}

export default PageHeader

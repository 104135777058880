import React, { useState, useEffect } from "react";
import "./sustainabality.scss";
import { BsGlobeAmericas } from "react-icons/bs";
import { GiRecycle } from "react-icons/gi";
import { GiTrade } from "react-icons/gi";
import { MdCo2 } from "react-icons/md";

const Sustainability = () => {
  const [values, setValues] = useState({
    eco: 0,
    recycle: 0,
    faretrade: 0,
    carbon: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setValues((prevValues) => ({
        eco: (prevValues.eco + Math.floor(Math.random() * 10)) % 100,
        recycle: (prevValues.recycle + Math.floor(Math.random() * 10)) % 100,
        faretrade:
          (prevValues.faretrade + Math.floor(Math.random() * 10)) % 100,
        carbon: (prevValues.carbon + Math.floor(Math.random() * 10)) % 100,
      }));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="sustainability-wrapper">
      <div className="sustainability-header">
        <h1 className="sustainability-title">SUSTAINABILITY</h1>
      </div>
      <div className="sustainability-images-row">
        <div className="sustainability-image-container">
          <BsGlobeAmericas className="icon" />
          <div className="sustainability-image-text">Eco friendly</div>
          <div className="sustainability-value">{values.eco}%</div>
        </div>
        <div className="sustainability-image-container">
          {/* <img src={recycle} alt="Recycling" /> */}
          <GiRecycle className="icon" />
          <div className="sustainability-image-text">Recycling</div>
          <div className="sustainability-value">{values.recycle}%</div>
        </div>
        <div className="sustainability-image-container">
          {/* <img src={faretrade} alt="Faretrade" /> */}
          <GiTrade className="icon" />
          <div className="sustainability-image-text">Faretrade</div>
          <div className="sustainability-value">{values.faretrade}%</div>
        </div>
        <div className="sustainability-image-container">
          {/* <img src={carbon} alt="Carbon Footprint" /> */}
          <MdCo2 className="icon" />
          <div className="sustainability-image-text">Carbon</div>
          <div className="sustainability-value">{values.carbon}%</div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>Phase3</div>
    </div>
  );
};

export default Sustainability;
